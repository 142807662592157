import Button from '@ingka/button'
import arrowRight from '@ingka/ssr-icon/paths/arrow-right'
import documentMagnifyingGlass from '@ingka/ssr-icon/paths/document-magnifying-glass'
import laptopPencil from '@ingka/ssr-icon/paths/laptop-pencil'
import pieChart from '@ingka/ssr-icon/paths/pie-chart'
import reward from '@ingka/ssr-icon/paths/reward'
import star from '@ingka/ssr-icon/paths/star'
import { space50 } from '@ingka/variables'
import { Flex, Grid, Stack } from '@mantine/core'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'

import { CoachingSessionStatus } from 'lib/types/coachingSession.types'
import { MissionStatus } from 'lib/types/mission.types'
import { CoachingSessionHelper } from 'lib/utils/coachingSession.helper'

import { useSectionPaths } from 'hooks/navigation/useSectionPaths.hooks'
import { useValidateParams } from 'hooks/navigation/useValidateParams'

import { useCoachingSessions, useCoworker, useMissions } from 'state/slices/api'

import { colourNeutralGrey200 } from 'styles/tokens/oldSkapa.tokens'

import { Widget, WidgetFooter, WidgetHeader } from 'components/composites/Shared/Widget'
import { CoworkerSignCompletedSession } from 'components/features/Coworkers/CoworkerSignCompletedSession'
import NextScheduledCoaching from 'components/features/Coworkers/CoworkersOverview/CoworkerStatus/NextCoachingStatus.component'
import { PageHeader } from 'components/layout/PageHeader'
import { RedirectingLayout } from 'components/layout/RedirectingLayout'
import { CoworkerLandingPage } from 'components/pages/admin'
import { FeatureToggle } from 'components/shared/FeatureToggle'

import { useBackHref } from './CoworkerPage.hooks'

const CoworkerPage: React.FC = () => {
  const { t } = useTranslation()
  const { coworkerId } = useParams()
  const { coworkerPath } = useSectionPaths()
  const backHref = useBackHref()

  const { data: coworker } = useCoworker(coworkerId)
  const { data: missions = [] } = useMissions(coworkerId ?? skipToken)

  // Pre-fetch coaching sessions
  const { data: coachingSessions = [] } = useCoachingSessions(coworkerId ?? skipToken)

  useValidateParams('coworkerId')

  if (!coworkerId) return <RedirectingLayout />

  const activeMissions = missions.filter((mission) => mission.status === MissionStatus.Active)

  const previousSession = CoachingSessionHelper.getPreviousCoachingSession(coachingSessions)

  const shouldRenderAwaitingSignCard =
    previousSession?.status === CoachingSessionStatus.Completed || false

  return (
    <div data-testid="page-coworker">
      <FeatureToggle featureKey="coworkerNew">
        <CoworkerLandingPage />
      </FeatureToggle>

      <FeatureToggle featureKey="coworkerOld">
        <PageHeader
          backHref={backHref}
          title={coworker ? `Hej ${coworker.firstName} 👋🏻` : 'Hej'}
          rightContent={
            <Flex
              align={'center'}
              style={{
                backgroundColor: colourNeutralGrey200,
                padding: space50,
                borderRadius: space50,
              }}
            >
              <NextScheduledCoaching coworkerId={coworkerId} />
            </Flex>
          }
        />
        <Stack>
          {shouldRenderAwaitingSignCard && (
            <CoworkerSignCompletedSession
              coworkerId={coworkerId}
              completedSessionAwaiting={previousSession!}
            />
          )}
          <Grid>
            <Grid.Col span={6}>
              <Widget
                header={
                  <WidgetHeader
                    ssrIcon={star}
                    title={t('features.mission.missions-overview.title')}
                  />
                }
                footer={
                  <WidgetFooter justify="flex-start">
                    {activeMissions.length !== 0 && (
                      <Link to={`${coworkerPath}/missions`}>
                        <Button
                          data-testid="btn-to-missions"
                          type="tertiary"
                          small
                          text={t('navigation.links.see-all-missions')}
                          ssrIcon={arrowRight}
                          iconPosition="trailing"
                        />
                      </Link>
                    )}
                  </WidgetFooter>
                }
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Widget
                header={
                  <WidgetHeader
                    ssrIcon={documentMagnifyingGlass}
                    title={t('features.coaching-session.coaching-sessions')}
                  />
                }
                footer={
                  <WidgetFooter justify="flex-start">
                    <Link to={`${coworkerPath}/coaching-sessions`}>
                      <Button
                        data-testid="btn-to-coaching-sessions"
                        type="tertiary"
                        small
                        text={t('features.coaching-session.see-all-coaching-sessions')}
                        ssrIcon={arrowRight}
                        iconPosition="trailing"
                      />
                    </Link>
                  </WidgetFooter>
                }
              />
            </Grid.Col>

            <Grid.Col span={6}>
              <Widget
                header={
                  <WidgetHeader
                    ssrIcon={pieChart}
                    title={t('pages.explore.title')}
                    subtitle={t('pages.explore.teaser')}
                  />
                }
                footer={
                  <WidgetFooter>
                    <Link to={`${coworkerPath}/explore`}>
                      <Button
                        data-testid="btn-to-explore"
                        type="tertiary"
                        small
                        iconOnly
                        ssrIcon={arrowRight}
                      />
                    </Link>
                  </WidgetFooter>
                }
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Widget
                header={
                  <WidgetHeader
                    ssrIcon={laptopPencil}
                    title={t('pages.qa.title')}
                    subtitle={t('pages.qa.teaser')}
                  />
                }
                footer={
                  <WidgetFooter>
                    <Link to={`${coworkerPath}/qa`}>
                      <Button
                        data-testid="btn-to-qa"
                        type="tertiary"
                        small
                        iconOnly
                        ssrIcon={arrowRight}
                      />
                    </Link>
                  </WidgetFooter>
                }
              />
            </Grid.Col>

            <FeatureToggle featureKey="badges">
              <Grid.Col span={6}>
                <Widget
                  header={
                    <WidgetHeader
                      ssrIcon={reward}
                      title={t('pages.badges.title')}
                      subtitle={t('pages.badges.teaser')}
                    />
                  }
                  footer={
                    <WidgetFooter>
                      <Link to={`${coworkerPath}/badges`}>
                        <Button
                          data-testid="btn-to-badges"
                          type="tertiary"
                          small
                          iconOnly
                          ssrIcon={arrowRight}
                        />
                      </Link>
                    </WidgetFooter>
                  }
                />
              </Grid.Col>
            </FeatureToggle>
          </Grid>
        </Stack>
      </FeatureToggle>
    </div>
  )
}

export default CoworkerPage

import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'

import { AchievedBadge, Coworker } from 'lib/types/coworker.types'

import { apiSlice } from 'state/slices/api/api.slice'

import { sessionSlice } from '../coachingSession/coachingSession.slice'
import { customerCommentTeamleadSlice } from '../customerComment/customerCommentTeamlead.slice'
import { missionSlice } from '../mission/mission.slice'
import { qualityAssessmentSlice } from '../qualityAssessment/qualityAssessment.slice'
import { surveyMetricSlice } from '../surveyMetric/surveyMetric.slice'

type FetchCoworkersByTeamleadIdResponse = {
  activeCoworkers: Coworker[]
}

type SetCoworkerRoleParams = {
  coworkerId: string
  role: string
}

type AwardBadgeParams = {
  coworkerId: string
  awarderId: string
  badgeId: string
  message: string
}

export const coworkerSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    awardBadge: builder.mutation<AchievedBadge, AwardBadgeParams>({
      query: ({ coworkerId, awarderId, badgeId, message }) => ({
        url: `/coworkers/${coworkerId}/award-badge`,
        method: 'POST',
        body: { awarderId, badgeId, message },
      }),
      invalidatesTags: ['Coworker'],
    }),

    setCoworkerRole: builder.mutation<Coworker, SetCoworkerRoleParams>({
      query: ({ coworkerId, role }) => ({
        url: `/coworkers/${coworkerId}/role`,
        method: 'POST',
        body: { coworkerId, role },
      }),
      invalidatesTags: ['Coworker'],
    }),
    findCoworkerById: builder.query<Coworker, string>({
      query: (coworkerId) => `/coworkers/${coworkerId}`,
      providesTags: ['Coworker'],
    }),
    fetchCoworkersByTeamleadId: builder.query<FetchCoworkersByTeamleadIdResponse, string>({
      query: (teamleadId) => `/coworkers/byTeamleadId/${teamleadId}`,
      transformErrorResponse: (error: FetchBaseQueryError) => {
        if (error.status === 404) return []
        else throw error
      },
      // TODO: Inspect those tags!
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data: coworkers } = await queryFulfilled
          coworkers.activeCoworkers.forEach(async (coworker) => {
            dispatch(
              customerCommentTeamleadSlice.util.upsertQueryData(
                'getCustomerComments',
                coworker.legacyId,
                coworker.customerComments
              )
            )

            dispatch(
              sessionSlice.util.upsertQueryData(
                'findCoachingSessionsByCoworkerId',
                coworker.legacyId,
                coworker.coachingSessions
              )
            )
            dispatch(
              qualityAssessmentSlice.util.upsertQueryData(
                'findRecentQAsForCoworker',
                coworker.legacyId,
                coworker.recentQAs
              )
            )

            dispatch(
              surveyMetricSlice.util.upsertQueryData(
                'getSurveyMetricsByCoworkerId',
                coworker.legacyId,
                coworker.surveyMetrics
              )
            )
            dispatch(
              missionSlice.util.upsertQueryData(
                'findMissionsByCoworkerId',
                coworker.legacyId,
                coworker.missions
              )
            )
          })
        } catch (error) {
          console.error('Failed to fetch coworkers or comments:', error)
        }
      },
      providesTags: ['Coworker'],
    }),
  }),
})

export const {
  useFindCoworkerByIdQuery,
  useFetchCoworkersByTeamleadIdQuery,
  useAwardBadgeMutation,
  useSetCoworkerRoleMutation,
} = coworkerSlice

import Placeholder from '@tiptap/extension-placeholder'
import { useEditor } from '@tiptap/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { commonExtensions } from '../constants'
import { isJsonString } from '../utils'

interface RTFEditorOptions {
  placeholderText?: string
  editableFromStart?: boolean
  autofocus?: boolean
}

export const useTextEditor = (
  initialValue: string | undefined,
  { placeholderText, editableFromStart = false, autofocus = false }: RTFEditorOptions = {}
) => {
  initialValue = isJsonString(initialValue) ? JSON.parse(initialValue!) : initialValue ?? ''
  const { t } = useTranslation()
  const [isEditable, setIsEditable] = useState<boolean>(editableFromStart)
  const [value, setValue] = useState<string>(initialValue!)

  const editor = useEditor({
    content: initialValue,
    autofocus,
    editable: isEditable,
    onUpdate({ editor }) {
      const content = editor.getJSON()
      setValue(JSON.stringify(content))
    },
    extensions: [
      Placeholder.configure({
        placeholder: t(placeholderText ?? 'common.placeholder-text.editor-placeholder'),
        showOnlyWhenEditable: false,
      }),
      ...commonExtensions,
    ],
  })

  return {
    editor,
    isEditable,
    setIsEditable,
    value,
  }
}
import Pill from '@ingka/pill'
import Skeleton from '@ingka/skeleton'
import { radiusS } from '@ingka/variables'
import { Flex } from '@mantine/core'
import { t } from 'i18next'
import { useEffect, useState } from 'react'

import { useGetTeamleadCustomerComments, useGetTeamleadForwardedComments } from 'state/slices/api'

import {
  BrandColour,
  colourGreyNeutral1,
  colourGreyNeutral2,
  colourGreyNeutral3,
  spacing50,
  spacing75,
  spacing100,
} from 'styles/tokens/insikt.tokens'

import { EmptyContent } from 'components/composites/Shared/EmptyContent'
import { NumberTag } from 'components/composites/Shared/NumberTag'

import { CustomerCommentPreview } from './CustomerCommentPreview'
import { CustomerCommentsFilterKeys, customerCommentsFilterMenu } from './CustomerComments.helper'
import { CustomerCommentCTAProps } from './CustomerComments.types'

const CustomerCommentsOverview: React.FC<CustomerCommentCTAProps> = ({
  coworkerId,
  availableActions,
  onForward,
  onDismiss,
}) => {
  const { data: customerComments = [], isLoading: teamleadCommentsLoading } =
    useGetTeamleadCustomerComments(coworkerId)

  const {
    data: forwardedComments = [],
    refetch: refetchForwardedComments,
    isLoading: isForwardedLoading,
  } = useGetTeamleadForwardedComments(coworkerId)

  const [cardActions, setCardActions] = useState<{ [key: string]: 'forward' | 'dismiss' | null }>(
    {}
  )

  const [activeTab, setActiveTab] = useState<CustomerCommentsFilterKeys>(
    CustomerCommentsFilterKeys.AllComments
  )

  const handleConfirm = async (id: string, action: 'forward' | 'dismiss') => {
    setCardActions((prev) => ({ ...prev, [id]: action }))
    try {
      if (action === 'forward' && onForward) {
        await onForward(id)
      } else if (action === 'dismiss' && onDismiss) {
        await onDismiss(id)
      }
    } catch (e) {
      console.error(e)
      setCardActions((prev) => ({ ...prev, [id]: null }))
    }
  }

  const allCustomerComments = customerComments.filter((comment) => comment.status !== 'forwarded')

  useEffect(() => {
    if (activeTab === CustomerCommentsFilterKeys.ForwardedComments) {
      refetchForwardedComments()
    }
  }, [activeTab, refetchForwardedComments])

  if (teamleadCommentsLoading)
    return (
      <Skeleton
        style={{
          minHeight: '50vh',
          minWidth: 'fit-content',
          background: colourGreyNeutral3,
          marginTop: spacing100,
        }}
      />
    )

  return (
    <>
      <Flex
        bg={colourGreyNeutral1}
        justify={'flex-start'}
        gap={spacing50}
        my={spacing100}
        p={`${spacing75} ${spacing50}`}
        style={{ borderRadius: radiusS, borderBottom: `1px solid ${colourGreyNeutral3}` }}
        h={'fit-content'}
      >
        {customerCommentsFilterMenu.map((tab) => (
          <Pill
            key={tab.key}
            label={t(tab.tKeyForTitle)}
            size="xsmall"
            style={{
              background: colourGreyNeutral2,
              padding: `${spacing50} ${spacing100}`,
              border: activeTab === tab.key ? `2px solid ${BrandColour.Blue}` : 'none',
            }}
            onClick={() => setActiveTab(tab.key)}
          />
        ))}
      </Flex>

      {activeTab === CustomerCommentsFilterKeys.AllComments && (
        <>
          <NumberTag
            count={allCustomerComments.length}
            text={t('features.customer-comments.title')}
            subtitle={t('features.customer-comments.helper-text.all-comments')}
          />
          {allCustomerComments.length === 0 ? (
            <EmptyContent translationKey={t('features.customer-comments.warnings.no-comments')} />
          ) : (
            <CustomerCommentPreview
              customerComments={allCustomerComments}
              availableActions={availableActions}
              cardActions={cardActions}
              onConfirm={handleConfirm}
            />
          )}
        </>
      )}

      {activeTab === CustomerCommentsFilterKeys.ForwardedComments && (
        <>
          <NumberTag
            count={forwardedComments.length}
            text={t('features.customer-comments.title')}
            subtitle={t('features.customer-comments.helper-text.forwarded-comments')}
          />
          {forwardedComments.length === 0 && (
            <EmptyContent
              translationKey={t('features.customer-comments.warnings.no-forwarded-comments')}
            />
          )}

          {isForwardedLoading ? (
            <Skeleton
              style={{
                minHeight: '50vh',
                minWidth: 'fit-content',
                background: colourGreyNeutral3,
                marginTop: spacing100,
              }}
            />
          ) : (
            <CustomerCommentPreview
              customerComments={forwardedComments}
              availableActions={[]}
              cardActions={{}}
              onConfirm={() => { }}
            />
          )}
        </>
      )}
    </>
  )
}

export default CustomerCommentsOverview

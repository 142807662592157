import { useEditor } from '@tiptap/react'
import { commonExtensions } from '../constants'

export const useViewOnlyEditor = (displayText: string | undefined, fallbackText = '') => {

  return useEditor({
    editable: false,
    extensions: [...commonExtensions],
  })
}

import dayjs from 'dayjs'

import { noActionStatusThreshold } from 'lib/consts'
import { CoachingSessionStatus } from 'lib/types/coachingSession.types'
import { CoworkerCoachingStatus } from 'lib/types/coworker.types'
import { CoachingSessionHelper } from 'lib/utils/coachingSession.helper'

import { useCoachingSessions } from 'state/slices/api'
import { useRecentQAs } from 'state/slices/api/modules/qualityAssessment/qualityAssessment.slice'

interface Return {
  assessmentsCompleted?: number
  assessmentsNeeded?: number
  status: CoworkerCoachingStatus | undefined
}

export const useCoworkerStatus = (coworkerId: string): Return => {
  const { data: coachingSessions = [] } = useCoachingSessions(coworkerId)

  const { data: recentQaData } = useRecentQAs(coworkerId)

  const assessments = recentQaData?.QAs ?? []

  const today = dayjs().startOf('date')

  const currentCoachingSession = CoachingSessionHelper.getCurrentCoachingSession(coachingSessions)

  // If the coworker has no sessions
  if (!currentCoachingSession) {
    return { status: CoworkerCoachingStatus.Plan }
  }

  // Define dates necessary for calculations
  const sessionPlannedDate = dayjs(currentCoachingSession.plannedDate).startOf('date')

  const dueThreshold = sessionPlannedDate.subtract(noActionStatusThreshold, 'days')

  // Check which state the upcoming session is in; draft, in progress, completed or signed
  // Do we need still this switch? We ensure that we should not be in that state in the code right?
  switch (currentCoachingSession.status) {
    case CoachingSessionStatus.Planned:
      // If today is further away than 10 days
      if (today.isBefore(dueThreshold)) {
        return { status: CoworkerCoachingStatus.NoAction }
      }

      // If upcoming coaching session does not have all assessments completed
      if (assessments.length < currentCoachingSession.assessmentsNeeded) {
        // Return need-assessments

        return {
          status: CoworkerCoachingStatus.Assess,
          assessmentsCompleted: assessments.length,
          assessmentsNeeded: currentCoachingSession.assessmentsNeeded,
        }
      }

      // If today is overdue based on the planned date
      if (today.isAfter(sessionPlannedDate) || today.isSame(sessionPlannedDate)) {
        return { status: CoworkerCoachingStatus.Coach }
      }

      break

    case CoachingSessionStatus.Completed:
      return { status: CoworkerCoachingStatus.Plan }

    case CoachingSessionStatus.Signed:
      return { status: CoworkerCoachingStatus.Plan }

    default:
      break
  }

  // Else return no draft
  return { status: CoworkerCoachingStatus.NoAction }
}

import { Skeleton } from '@mantine/core'

import { RTFTextEditor } from '../RTFTextEditor'
import { useViewOnlyEditor } from './RTFViewBox.hooks'
import { isJsonString } from '../utils'

interface Props {
  text: string | undefined
  fallbackText?: string
}

export const RTFViewBox = ({ text, fallbackText }: Props) => {
  const editor = useViewOnlyEditor(text, fallbackText)

  // We actually want to use || here instead of ?? because we want to check if displayText is an empty string
  const content = isJsonString(text) ? JSON.parse(text!) : text || fallbackText

  if (!editor) return <Skeleton />

  // Call this here instead of the hook to force re-render on text change
  editor.commands.setContent(content)

  return <RTFTextEditor editor={editor} />
}

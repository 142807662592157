import { RTFViewBox } from 'components/shared/RtfFeatures/RTFViewBox/RTFViewBox.component'

type MissionCoachingPointersFormattedProps = {
  coachingPointers: string
}

// Review notes: Should we remove this? This is really just a wrapper
const MissionCoachingPointersFormatted: React.FC<MissionCoachingPointersFormattedProps> = ({
  coachingPointers,
}) => {
  return <RTFViewBox text={coachingPointers} />
}

export default MissionCoachingPointersFormatted

import { CoachingSession } from 'lib/types/coachingSession.types'

import { apiSlice } from 'state/slices/api/api.slice'

import {
  CompleteCoachingSessionArgs,
  CoworkerNote,
  CreateCoachingSessionArgs,
  CreateCoworkerNoteArgs,
  FindCoachingSessionArgs,
  FindCoworkerNoteArgs,
  UpdateCoachingSessionArgs,
  UpdateSessionCommentArgs,
} from './coachingSession.slice.types'

export const sessionSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    findCoachingSessionByIdAndCoworkerId: builder.query<CoachingSession, FindCoachingSessionArgs>({
      query: ({ coachingSessionId, coworkerId }) =>
        `/coworkers/${coworkerId}/coachingSessions/${coachingSessionId}`,
      providesTags: (result, error, { coachingSessionId }) => [
        { type: 'Coaching Session', id: coachingSessionId },
      ],
    }),
    findCoachingSessionsByCoworkerId: builder.query<CoachingSession[], string>({
      query: (coworkerId) => `/coworkers/${coworkerId}/coachingSessions`,
      async onQueryStarted(coworkerId, { dispatch, queryFulfilled }) {
        try {
          const { data: coachingSessions } = await queryFulfilled
          coachingSessions.forEach((coachingSession) =>
            dispatch(
              sessionSlice.util.upsertQueryData(
                'findCoachingSessionByIdAndCoworkerId',
                {
                  coachingSessionId: coachingSession.id,
                  coworkerId,
                },
                coachingSession
              )
            )
          )
        } catch {
          return
        }
      },
      providesTags: (result, error, coworkerId) => [{ type: 'Coaching Session', id: coworkerId }],
    }),
    createCoachingSession: builder.mutation<string, CreateCoachingSessionArgs>({
      query: ({ coworkerId, createdBy, createdDate, plannedDate, assessmentsNeeded }) => ({
        url: `/coworkers/${coworkerId}/coachingSessions`,
        method: 'POST',
        body: { createdBy, createdDate, plannedDate, assessmentsNeeded },
        responseHandler: 'text',
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Coaching Session', id: arg.coworkerId }],
    }),
    updateCoachingSession: builder.mutation<string, UpdateCoachingSessionArgs>({
      query: ({ coworkerId, coachingSessionId, assessmentsNeeded, plannedDate }) => ({
        url: `/coworkers/${coworkerId}/coachingSessions/${coachingSessionId}/update`,
        method: 'PUT',
        body: { assessmentsNeeded, plannedDate },
        responseHandler: 'text',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Coaching Session', id: arg.coworkerId },
        { type: 'Coaching Session', id: arg.coachingSessionId },
      ],
    }),
    updateSessionComment: builder.mutation<void, UpdateSessionCommentArgs>({
      query: ({ coachingSessionId, coworkerId, sessionComment }) => ({
        url: `/coworkers/${coworkerId}/coachingSessions/${coachingSessionId}/sessionComment`,
        method: 'PUT',
        body: { sessionComment },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Coaching Session', id: arg.coworkerId },
        { type: 'Coaching Session', id: arg.coachingSessionId },
      ],
    }),
    completeCoachingSession: builder.mutation<void, CompleteCoachingSessionArgs>({
      query: ({ coachingSessionId, coworkerId, completedDate, sessionScore }) => ({
        url: `/coworkers/${coworkerId}/coachingSessions/${coachingSessionId}/complete`,
        method: 'PUT',
        body: {
          completedDate,
          scores: sessionScore,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Coaching Session', id: arg.coworkerId },
        { type: 'Mission', id: arg.coworkerId },
      ],
    }),
    findCoworkerNoteByCoachingSessionId: builder.query<CoworkerNote, FindCoworkerNoteArgs>({
      query: ({ coachingSessionId, coworkerId }) =>
        `/coworkers/${coworkerId}/coachingSessions/${coachingSessionId}/note`,
      providesTags: (result, error, { coachingSessionId }) => [
        { type: 'Coaching Session', id: coachingSessionId },
      ],
    }),
    createCoworkerNote: builder.mutation<void, CreateCoworkerNoteArgs>({
      query: ({ coachingSessionId, coworkerId, coworkerNote }) => ({
        url: `/coworkers/${coworkerId}/coachingSessions/${coachingSessionId}/note`,
        method: 'PUT',
        body: {
          note: coworkerNote.note,
          createdDate: coworkerNote.createdDate,
          createdBy: coworkerNote.createdBy,
          isAvailableToTeamlead: false,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Coaching Session', id: arg.coworkerId },
        { type: 'Coaching Session', id: arg.coachingSessionId },
      ],
    }),
  }),
})

export const {
  useFindCoachingSessionByIdAndCoworkerIdQuery: useCoachingSession,
  useFindCoachingSessionsByCoworkerIdQuery: useCoachingSessions,
  useCreateCoachingSessionMutation: useCreateCoachingSession,
  useCompleteCoachingSessionMutation: useCompleteCoachingSession,
  useUpdateSessionCommentMutation: useUpdateSessionComment,
  useUpdateCoachingSessionMutation: useUpdateCoachingSession,
  useCreateCoworkerNoteMutation: useCreateCoworkerNote,
  useFindCoworkerNoteByCoachingSessionIdQuery: useCoworkerNote,
} = sessionSlice
